import axios from 'axios'
import { setApi, setNewData } from '@/utils/base.js'
const api = setApi()
    /*f
    获取团队列表
    geteway api：
    [GET] http://xapi-test.beanotes.com/team 
    local api：http://127.0.0.1:6002/team
    */
export const teamRequest = (data) => {
        return axios.get(api + `/team?page=${data.page}&team=${data.team}`)
    }
    /*
    图书列表
    geteway api：[GET] http://xapi-test.beanotes.com/books
    local api：http://127.0.0.1:6002/books
    获取图书列表
    获取我的图书  /books/ours
    待发布图书      /books/progress
    已购图书      /books/bought
    */
export const booksRequest = (data) => {
    const newdata = setNewData(data)
    return axios.get(api + `/books?` + newdata)
}
export const booksBoughtRequest = (data) => {
        return axios.get(api + `/books/bought?page=${data.page}`)
    }
    /*
    图书介绍页
    geteway api：[GET] http://xapi-test.beanotes.com/books/overview/:name
    local api：http://127.0.0.1:6002/books/overview/:name
     */
export const booksOverviewRequest = (data) => {
        return axios.get(api + `/books/overview/${data.name}`)
    }
    /*
    获取我的图书
    geteway api：[GET] http://xapi-test.beanotes.com/books/ours
     */
export const booksOursRequest = (data) => {
        return axios.get(api + `/books/ours?page=${data.page}`)
    }
    /*
    待发布图书
    geteway api：[GET] http://xapi-test.beanotes.com/books/progress
     */
export const booksProgressRequest = (data) => {
        return axios.get(api + `/books/progress?page=${data.page}`)
    }
    /*
    发布图书提交审核
    geteway api：
    [POST] http://xapi-test.beanotes.com/books/publish
    local api：
    http://127.0.0.1:6002/books/publish
     */
export const booksPublishRequest = (data) => {
        return axios.post(api + `/books/publish`, data)
    }
    /*
    删除图书
    geteway api：
    [GET] http://xapi-test.beanotes.com/books/leave/:bookid
    local api：
    http://127.0.0.1:6002/books/leave/:bookid
     */
export const booksLeaveRequest = (data) => {
        return axios.delete(api + `/books/leave/${data.bookid}`)
    }
    /**
     发布图书提交审核
    geteway api：
    [GET] http://xapi-test.beanotes.com/books/publish
    local api：
    http://127.0.0.1:6002/books/publish
     */
    // export const booksPublishRequest = (data) => {
    //     return axios.post(api + `/books/publish/${data.bookid}`)
    // }
    /*
    保存图书简介
    geteway api：[POST] http://xapi-test.beanotes.com/books/intro
    local api：http://127.0.0.1:6002/books/intro
    */
export const booksIntroRequest = (data) => {
        return axios.post(api + `/books/intro`, data, {
            headers: {
                'Content-Type': 'multipart/form-data;'
            }
        })
    }
    /**
     * 
    *获取图书简介
    geteway api：
    [GET] http://xapi-test.beanotes.com/books/intro
    local api：
    http://127.0.0.1:6002/books/intro} data 
     */
export const BooksIntroRequest = (data) => {
        return axios.get(api + `/books/intro?bookid=${data.bookid}`)
    }
    /*
    获取图书目录信息
    geteway api：[GET] http://xapi-test.beanotes.com/t/:name
    local api：http://127.0.0.1:6002/t/:name */
export const booksNameRequest = (data) => {
        return axios.get(api + `/t/${data.name}`)
    }
    /**
     删除目录章节
    geteway api：[DELETE] http://xapi-test.beanotes.com/books/section
    local api：http://127.0.0.1:6002/books/section
     */
export const booksSectionDelRequest = (data) => {
        return axios.delete(api + `/books/section`, { data })
    }
    /**
     添加或编辑目录章节geteway api：[POST] http://xapi-test.beanotes.com/books/section
     */
export const booksSectionAddRequest = (data) => {
        return axios.post(api + `/books/section`, data)
    }
    /**
    获取章节内容
    geteway api：[GET] http://xapi-test.beanotes.com/books/section/:secid
    local api：http://127.0.0.1:6002/books/section/:secid
     */
export const booksSectionRequest = (data) => {
        return axios.get(api + `/books/section/${data.secid}`)
    }
    /*
    保存章节文章内容
    geteway api：[POST] http://xapi-test.beanotes.com/books/article
    local api：http://127.0.0.1:6002/books/article */
export const booksArticleRequest = (data) => {
        return axios.post(api + `/books/article`, data)
    }
    /*
    专栏最近更新
    geteway api：[GET] http://xapi-test.beanotes.com/special
    local api：http://127.0.0.1:6002/special
     */
export const specialRequest = (data) => {
        return axios.get(api + `/special?page=${data.page}`)
    }
    /**
     * 全部专栏
    geteway api：[GET] http://xapi-test.beanotes.com/special/gather
    local api：http://127.0.0.1:6002/special/gather

    获取全部专栏   /special/gather

    获取我的专栏    /special/gather/ours

    获取已购专栏   /special/gather/bought
     */
export const specialGatherRequest = (data) => {
    const newdata = setNewData(data)
    return axios.get(api + `/special/gather?${newdata}`)
}
export const specialGatherOursRequest = (data) => {
    return axios.get(api + `/special/gather/ours?page=${data.page}`)
}
export const specialGatherBoughtRequest = (data) => {
        return axios.get(api + `/special/gather/bought?page=${data.page}`)
    }
    /*
    专栏主页
    geteway api：[GET] http://xapi-test.beanotes.com/s/:identify
    local api：http://127.0.0.1:6002/s/:identify */
export const specialIdentifyRequest = (data) => {
        return axios.get(api + `/s/${data.Identify}`)
    }
    /*
    专栏文章列表
    geteway api：[GET] http://xapi-test.beanotes.com/special/polymer/:sid/article
    local api：http://127.0.0.1:6002/special/polymer/:sid/article */
export const specialPolymerRequest = (data) => {
        return axios.get(api + `/special/polymer/${data.Identify}/article?page=${data.page}`)
    }
    /*
    专栏文章内容
    geteway api：[GET] http://xapi-test.beanotes.com/article/:artid
    local api：http://127.0.0.1:6002/article/:artid */
export const specialArticleRequest = (data) => {
        return axios.get(api + `/article/${data.artid}`)
    }
    /*
    获取评论信息
    geteway api：
    [GET] http://xapi-test.beanotes.com/comment/ctype/:ctype/object/:objectid
    local api：
    http://127.0.0.1:6002/comment/ctype/:ctype/object/:objectid
    */
export const specialCommentRequest = (data) => {
        return axios.get(api + `/comment/ctype/${data.ctype}/object/${data.objectid}?page=${data.page}`)
    }
    /*
    评论发布
    geteway api：[GET] http://xapi-test.beanotes.com/comment/publish
    local api：http://127.0.0.1:6002/comment/publish */
export const commentPublishRequest = (data) => {
    return axios.post(api + `/comment/publish`, data)
}

/**
评论点赞
geteway api：[POST] http://xapi-test.beanotes.com/comment/support
local api：http://127.0.0.1:6002/comment/support
 */
export const commentSupportRequest = (data) => {
        return axios.post(api + `/comment/support`, data)
    }
    /*
    评论删除
    geteway api：[DELETE] http://xapi-test.beanotes.com/comment/leave/:commentid
    local api：http://127.0.0.1:6002/comment/leave/:commentid */
export const commentLeaveRequest = (data) => {
        return axios.delete(api + `/comment/leave/${data.commentid}`)
    }
    /**保存专栏文章
    geteway api：[POST] http://xapi-test.beanotes.com/special/article
    local api：http://127.0.0.1:6002/special/article */

export const saveSpecialArticleRequest = (data) => {
        return axios.post(api + `/special/article`, data)
    }
    /**
     * 获取用户所有的专栏
    geteway api：[GET] http://xapi-test.beanotes.com/special/own
    local api：http://127.0.0.1:6002/special/own
     */
export const specialOwnRequest = (data) => {
        return axios.get(api + `/special/own`)
    }
    /**
     * 获取团队图书
    geteway api：
    [GET] http://xapi-test.beanotes.com/team/books/:tid
    local api：
    http://127.0.0.1:6002/team/books/:tid
     */

export const teamBooksRequest = (data) => {
        console.log(data)
        return axios.get(api + `/team/books/${data.tid}?page=${data.page}`)
    }
    /**
     * 获取我的团队列表
    geteway api：[GET] http://xapi-test.beanotes.com/team/ours
    local api：http://127.0.0.1:6002/team/ours
     */
export const teamOursRequest = (data) => {
        return axios.get(api + `/team/ours?page=${data.page}`)
    }
    /**
     * 获取团队成员
    geteway api：[GET] http://xapi-test.beanotes.com/team/member/:tid
    local api：http://127.0.0.1:6002:/team/member/:tid
     */

export const teamMemberRequest = (data) => {
        return axios.get(api + `/team/member/${data.tid}`)
    }
    /**
     * 获取团队订单
    geteway api：[GET] http://xapi-test.beanotes.com/team/order/:tid
    local api：http://127.0.0.1:6002/team/order/:tid
     */
export const teamOrderRequest = (data) => {
        return axios.get(api + `/team/order/${data.tid}`)
    }
    /**
     * 获取团队信息
    geteway api：[GET] http://xapi-test.beanotes.com/team/info/:tid
    local api：http://127.0.0.1:6002/team/info/:tid
     */
export const teamInfoRequest = (data) => {
        return axios.get(api + `/team/info/${data.tid}`)
    }
    /**
     * 团队信息设置
    geteway api：[POST] http://xapi-test.beanotes.com/team/info
    local api：http://127.0.0.1:6002/team/info
     */
export const saveTeamInfoRequest = (data) => {

        return axios.post(api + `/team/info`, data, {
            //contentType: false,
            //processData: false,
            headers: {
                'Content-Type': 'multipart/form-data;'
            },
        })
    }
    /** 
     * 加入成员审核
    geteway api：[POST] http://xapi-test.beanotes.com/team/audit/member
    local api：http://127.0.0.1:6002/team/audit/member
    */
export const teamAuditMemberRequest = (data) => {
        return axios.post(api + `/team/audit/member`, data)
    }
    /**
     * 加入团队
    geteway api：[POST] http://xapi-test.beanotes.com/team/join/:tid
    local api：http://127.0.0.1:6002/team/join/:tid
     */
export const teamJoinRequest = (data) => {
        return axios.post(api + `/team/join/${data.tid}`, data)
    }
    /**
     * 专栏作者
    geteway api：
    [GET] http://xapi-test.beanotes.com/special/polymer/:identify/author
    local api：
    http://127.0.0.1:6002/special/polymer/:identify/author
     */
export const specialAuthorRequest = (data) => {
        return axios.get(api + `/special/polymer/${data.identify}/author`)
    }
    /**
     * 待发布专栏列表
    geteway api：[GET] http://xapi-test.beanotes.com/special/polymer/:identify/progress
    local api：http://127.0.0.1:6002/special/polymer/:identify/progress
     */
export const specialProgressRequest = (data) => {
    return axios.get(api + `/special/polymer/${data.identify}/progress?page=${data.page}`)
}

/**
 * 图片上传
 geteway api：[POST] http://xapi-test.beanotes.com/uploads/image
 local api：http://127.0.0.1:6002/uploads/image
 */
export const uploadsImageRequest = (data) => {

        return axios.post(api + `/uploads/image`, data, {
            //contentType: false,
            //processData: false,
            headers: {
                'Content-Type': 'multipart/form-data;'
            },
        })
    }
    /**
     * 删除专栏文章
    geteway api：[DELETE] http://xapi-test.beanotes.com/special/article/:artid
    local api：http://127.0.0.1:6002/special/article/:artid
     */
export const specialArticleDelRequest = (data) => {
    return axios.delete(api + `/special/article/${data.artid}`)
}

/**
 * 获取用户笔记
geteway api：[GET] http://xapi-test.beanotes.com/notes
local api：http://127.0.0.1:6002/notes
 */
export const notesRequest = (data) => {
    return axios.get(api + `/notes?page=${data.page}`)
}

/**
 * 获取笔记内容
geteway api：[GET] http://xapi-test.beanotes.com/notes/artcile/:artid
local api：http://127.0.0.1:6002/notes/artcile/:artid
 */
export const notesArtcileRequest = (data) => {
        return axios.get(api + `/notes/artcile/${data.artid}`)
    }
    /**
     * 保存笔记文章
    geteway api：[post] http://xapi-test.beanotes.com/notes/artcile
    local api：http://127.0.0.1:6002/notes/artcile
     */
export const saveNotesArtcileRequest = (data) => {
        return axios.post(api + `/notes/artcile`, data)
    }
    /**
     * 获取banner
    geteway api：[GET] http://xapi-test.beanotes.com/banner
    local api：http://127.0.0.1:6002/banner
     */
export const bannerRequest = (data) => {
        return axios.get(api + `/banner`)
    }
    /**
     * 用户最近访问
    geteway api：[GET] http://xapi-test.beanotes.com/notes/visit
    local api：http://127.0.0.1:6002/notes/visit
     */
export const notesVisitRequest = (data) => {
        return axios.get(api + `/notes/visit`)
    }
    /**
  * 保存专栏简介
geteway api：[POST] http://xapi-test.beanotes.com/special/intro
local api：http://127.0.0.1:6002/special/intro
  */
export const specialIntroRequest = (data) => {
        return axios.post(api + `/special/intro`, data, {
            headers: {
                'Content-Type': 'multipart/form-data;'
            }
        })
    }
    /**
     * 
     * 获取专栏简介
    geteway api：
    [GET] http://xapi-test.beanotes.com/special/intro
    local api：
    http://127.0.0.1:6002/special/intro
     */
export const SpecialIntroRequest = (data) => {
        return axios.get(api + `/special/intro?sid=${data.sid}`)
    }
    /**
     * 删除笔记
    geteway api：
    [GET] http://xapi-test.beanotes.com/notes/opt/:noteid
    local api：
    http://127.0.0.1:6002/notes/opt/:noteid
     */
    // delete
export const notesOptRequest = (data) => {
        return axios.delete(api + `/notes/opt/${data.noteid}`)
    }
    /**
     * 删除专栏
    geteway api：
    [DELETE] http://xapi-test.beanotes.com/special/leave/:sid
    local api：
    http://127.0.0.1:6002/special/leave/:sid
     */
export const specialLeaveRequest = (data) => {
        return axios.delete(api + `/special/leave/${data.sid}`)
    }
    /**
    获取团队专栏
    geteway api：
    [GET] http://xapi-test.beanotes.com/team/special/:tid
    local api：
    http://127.0.0.1:6002/team/special/:tid
     */
export const teamSpecialRequest = (data) => {
        return axios.get(api + `/team/special/${data.tid}?page=${data.page}`)
    }
    /*
    消息列表
    geteway api：
    [GET] http://xapi-test.beanotes.com/message
    local api：
    http://127.0.0.1:6002/message */
export const noticeListRequest = (data) => {
        return axios.get(api + `/message?type=${data.type}&page=${data.page}`)
    }
    /*
    个人信息 - 获取个人信息
    gateway api：【GET】 http://127.0.0.1:6001/users/person
    local api：https://xapi.smaty.net/users/person
    */
export const getUserPerson = () => {
        return axios.get(api + `/users/person`)
    }
    /*
    个人信息 - 检查个人信息填写
    gateway api：【POST】 http://127.0.0.1:6001/users/person/check
    local api：https://xapi.smaty.net/users/person/check
    */
export const checkUserPerson = (data) => {
        return axios.post(api + `/users/person/check`, data)
    }
    /*
    个人信息 - 发送手机短信验证码
    gateway api：【POST】 http://127.0.0.1:6001/users/getsms/${phone}
    local api：https://xapi.smaty.net/users/getsms/${phone}
    */
export const getPhoneSms = (data) => {
        return axios.post(api + `/users/sendSMS`, data)
    }
    /**
      发送邮件验证码
      geteway api：
      [POST] http://xapi-test.beanotes.com/users/sendEmail
      local api：
      http://127.0.0.1:6002/users/sendEmail

     */
export const getEmailSms = (data) => {
        return axios.post(api + `/users/sendEmail`, data)
    }
    /*
    个人信息 - 修改用户手机号
    gateway api：【POST】 http://127.0.0.1:6001/users/getsms/${phone}
    local api：https://xapi.smaty.net/users/getsms/${phone}
    */
export const setUserPhone = (data) => {
        return axios.post(api + `/users/phone`, data)
    }
    /**
   * 修改用户邮箱
geteway api：
[POST] http://xapi-test.beanotes.com/users/email
local api：
http://127.0.0.1:6002/users/email
   */
export const setUserEmail = (data) => {
        return axios.post(api + `/users/email`, data)
    }
    /*
    个人信息 - 个人信息保存
    gateway api：【POST】 http://127.0.0.1:6001/users/person/store
    local api：https://xapi.smaty.net/users/person/store
    */
export const saveUserPerson = (data) => {
        return axios.post(api + `/users/person/store`, data)
    }
    /**
   * 消息列表
geteway api：
[GET] http://xapi-test.beanotes.com/message
local api：
http://127.0.0.1:6002/message
   */
export const getMessage = (data) => {
        const newdata = setNewData(data)
        return axios.get(api + `/message?` + newdata)
    }
    /**
   * 获取交易商品信息
geteway api：
[GET] http://xapi-test.beanotes.com/trade/product/:ptype/:productid
local api：
http://127.0.0.1:6002/trade/product/:ptype/:productid
   */
export const getPayRequest = (data) => {
        return axios.get(api + `/trade/product/${data.ptype}/${data.productid}`)
    }
    /**
   * 创建订单
geteway api：
[GET] http://xapi-test.beanotes.com/trade/order
local api：
http://127.0.0.1:6002/trade/order
   */
export const setOrderRequest = (data) => {
        return axios.post(api + `/trade/order`, data)
    }
    /**
   * 取支付宝跳转链接
geteway api：
[POST] http://xapi-test.beanotes.com/trade/pay/alipay
local api：
http://127.0.0.1:6002/trade/pay/alipay
   */
export const payAlipayRequest = (data) => {
        return axios.post(api + `/trade/pay/alipay`, data)
    }
    /**
   * 用户订单列表
geteway api：
[GET] http://xapi-test.beanotes.com/trade/order
local api：
http://127.0.0.1:6002/trade/order
   */
export const getOrderRequest = (data) => {
        return axios.get(api + `/trade/order?page=${data.page}`)
    }
    /**
   * 获取用户提现记录
geteway api：
[GET] http://xapi-test.beanotes.com/trade/withdraw
local api：
http://127.0.0.1:6002/trade/withdraw
   */
export const getWithdrawRequest = () => {
        return axios.get(api + `/trade/withdraw`)

    }
    /**
   *  获取用户可提现金额
geteway api：
[GET] http://xapi-test.beanotes.com/trade/withdraw/balance
local api：
http://127.0.0.1:6002/trade/withdraw/balance
 */
export const getWithdrawBalanceRequest = () => {
        return axios.get(api + `/trade/withdraw/balance`)
    }
    /**
   * 绑定银行卡
geteway api：
[POST] http://xapi-test.beanotes.com/trade/withdraw/bank
local api：
http://127.0.0.1:6002/trade/withdraw/bank
   */

export const bandbankcard = (data) => {
        return axios.post(api + `/trade/withdraw/bank`, data)
    }
    /**
     * 用户提现
    geteway api：
    [POST] http://xapi-test.beanotes.com/trade/withdraw
    local api：
    http://127.0.0.1:6002/trade/withdraw
     */
export const withdrawRequest = (data) => {
        return axios.post(api + `/trade/withdraw`, data)
    }
    /**
   * 头像上传
geteway api：
[POST] http://xapi-test.beanotes.com/uploads/avatar
local api：
http://127.0.0.1:6002/uploads/avatar
   */
export const uploadsAvatarRequest = (data) => {
        return axios.post(api + `/uploads/avatar`, data, {
            headers: {
                'Content-Type': 'multipart/form-data;'
            }
        })
    }
    /**
   * 图书推荐
geteway api：
[GET] http://xapi-test.beanotes.com/books/recommend
local api：
http://127.0.0.1:6002/books/recommend
   */
export const getBooksRecommendRequest = () => {
        return axios.get(api + `/books/recommend`)
    }
    /**
   * 团队退出
geteway api：
[POST] http://xapi-test.beanotes.com/team/bolter
local api：
http://127.0.0.1:6002/team/bolter
   */
export const teamBolterRequest = (data) => {
    return axios.post(api + `/team/bolter`, data)
}
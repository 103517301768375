<template>
  <div class="teamorderbox">
    <h2 class="titleh2">金额类型：￥<span v-text="amount">--</span></h2>
    <el-table :data="listData" stripe size="smail" style="width: 100%"  v-loading="loading">
      <el-table-column prop="OType" label="类型" width="180"></el-table-column>
      <el-table-column prop="Product" label="产品" width="180"></el-table-column>
      <el-table-column prop="Paytime" label="日期"></el-table-column>
      <el-table-column prop="Buyuser" label="购买人"></el-table-column>
      <el-table-column prop="Money" label="金额（元）">
         <template slot-scope="scope">
          <span class="orgtexg">+{{scope.row.Money}}</span>
          </template>
      </el-table-column>
      <el-table-column prop="OrderStatus" label="状态">
        <template slot-scope="scope">
          <!-- greentexg  graytexg-->
          <span class="orgtexg">{{scope.row.OrderStatus}}</span>
          </template>
      </el-table-column>
    </el-table>
  </div>
</template>
<script>
import { teamOrderRequest } from "@/api/project.js";
export default {
  components: {},
  data() {
    return {
      loading:true,
      listData:[],
      amount:''
    };
  },
  created() {
    this.getList(this.tid);
  },
  computed: {
    tid() {
      return this.$route.params.tid || ""; //当前项目id
    }
  },
  methods: {
    async getList(tid) {
      try {
        const result = await teamOrderRequest({
          tid: tid
        });
        if (result.status == 200) {
          this.listData = result.data.list;
          this.amount=result.data.amount;
        }
         this.loading=false
      } catch (err) {}
    },
  }
};
</script>
<style  lang="less" scoped>
.teamorderbox {
  padding: 10px 25px 40px;
  .titleh2 {
    font-size: 14px;
    line-height: 24px;
    margin-bottom: 10px;
  }
  
}
/deep/.el-table thead th,
/deep/.el-table thead tr {
  background: #f4f7fc;
  height: 34px;
  padding: 0;
  font-size: 12px;
}
/deep/.el-table td,
/deep/.el-table tr {
  height: 38px;
  padding: 0;
  font-size: 12px;
}
.orgtexg{
  color: #FF6E00;
}
.greentexg{
  color: #009701;
}
.graytexg{
  color: #999;
}

</style>